@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fafafa !important;
}
